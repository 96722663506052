/* Personalize as setas de navegação do carrossel */
.carousel .control-next.control-arrow,
.carousel .control-prev.control-arrow {
  background-color: transparent;
  border: none;
}

.carousel .control-next.control-arrow:before,
.carousel .control-prev.control-arrow:before {
  color: #d87a16; /* Cor das setas */
  font-size: 24px; /* Tamanho das setas */
}

/* Opcional: Personalizar os indicadores */
.carousel .carousel-status {
  display: none; /* Esconde o status do carrossel */
}
